/* eslint no-console: 0 */

import Vue from 'vue/dist/vue.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import EbookForm from '@components/ebook_form/FintechEbookForm'

Vue.component('ebook-form', EbookForm)

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

extend('required', {
  ...required,
  message: 'This field is required'
})

extend('email', {
  ...email,
  message: 'This email address is invalid'
})

new Vue({ el: document.getElementById('ebook-form') })
