<template>
  <validation-provider :rules="rules" tag="div" class="form-input" v-slot="{ errors }">
    <input
      v-bind="attributes"
      @input="$emit('input', $event.target.value)"
      :value="value"
      :type="attributes.type || 'text'"
      class="input"
    />

    <label
      v-if="icon"
      :for="attributes.id"
      :class="['label label-icon', `svg-${icon}`]"
    />

    <span
      v-if="errors[0]"
      class="input-error"
    >
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    rules: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    attributes() {
      const { value, icon, error, ...other } = this.$attrs

      return other
    }
  }
}
</script>
