<template>
  <validation-observer v-slot="{ invalid }">
    <form class="form form-max-width" @submit.prevent="submit">
      <div class="form-row">
        <text-field
          v-model="request.firstname"
          id="ebook-form-firstname"
          name="firstname"
          icon="person"
          placeholder="First Name *"
          rules="required"
          :disabled="sent"
        />
      </div>

      <div class="form-row">
        <text-field
          v-model="request.lastname"
          id="ebook-form-lastname"
          name="lastname"
          icon="person"
          placeholder="Last Name *"
          rules="required"
          :disabled="sent"
        />
      </div>

      <div class="form-row">
        <text-field
          v-model="request.email"
          id="ebook-form-email"
          name="email"
          tyme="email"
          icon="mail"
          placeholder="Business email address *"
          rules="required|email"
          :disabled="sent"
        />
      </div>
      
      <check-field
        v-model="request.accept_processing"
        :rules="{ required: { allowFalse: false } }"
        :disabled="sent"
        id="ebook-form-accept_processing"
        name="accept_processing"
        type="checkbox"
      >
        <span>
          * I agree to the processing of my personal data provided above by Railwaymen Sp. z
          o.o. for direct contact and I accept
          <a class="link" href="https://learn.railwaymen.org/privacy-policy">Privacy
            Policy</a>.
        </span>
      </check-field>

      <check-field
        v-model="request.accept_marketing"
        :disabled="sent"
        :rules="{ required: { allowFalse: false } }"
        id="ebook-form-accept_marketing"
        name="accept_marketing"
      >
        <span>
          * I agree to receive marketing communication
          from Railwaymen Sp. z o.o. (newsletter, information about services, promotions, or news).
        </span>
      </check-field>
      <div class="form-row" style="margin: 0px 0px 30px;">* - required</div>

      <div class="margin-top-2">
        <strong
          v-if="sent"
          class="text-gradient"
        >
          Thanks for submitting the form!
        </strong>

        <button
          v-else
          class="btn gradient"
          type="submit"
          :disabled="invalid"
        >
          Download Free E-Book
        </button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import httpClient from '@utils/http_client'
import setCookies from '@utils/set_cookies'

import TextField from '@components/shared/TextField'
import CheckField from '@components/shared/CheckField'

import { FintechEbook } from '@models/fintech_ebook'

export default {
  components: {
    TextField,
    CheckField
  },
  data: () => ({
    request: new FintechEbook(),
    sent: false
  }),
  methods: {
    submit() {
      httpClient
        .post('/forms/fintech_ebook', { form: this.request })
        .then(() => {
          this.sent = true
          
          setCookies('has_access', '1', 90)
          window.location.assign('https://learn.railwaymen.org/thank-you-ebook-fintech')
        }).catch(error => {
          alert('Oops, something goes wrong, please try again later.')
        })
    }
  }
}

</script>
